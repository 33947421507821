<template>
    <div>
        <v-data-table
            :disable-pagination="disablePagination"
            :items-per-page="itemsPerPage"
            :hide-default-footer="disablePagination"
            :headers="headers"
            :items="Bundles"
            calculate-widths
            class="mt-3 appic-table-light specification-table"
            dense
            disable-sort
            hide-default-header
            item-key="index"
            single-select
        >
            <template v-slot:header="{ props: { headers } }">
                <thead>
                    <tr>
                        <th class="grey lighten-3 pa-1 text-center width-1-pct">{{ headers[0].text }}</th>
                        <th class="grey lighten-3 pa-1 text-no-wrap width-4-pct">{{ headers[1].text }}</th>
                        <th class="grey lighten-3 pa-1 text-no-wrap width-6-pct">{{ headers[2].text }}</th>
                        <th class="grey lighten-3 pa-1 text-no-wrap">{{ headers[3].text }}</th>
                        <th class="grey lighten-3 pa-1 text-no-wrap width-6-pct">{{ headers[4].text }}</th>
                        <th class="grey lighten-3 pa-1 text-no-wrap width-6-pct">{{ headers[5].text }}</th>
                        <th class="grey lighten-3 pa-1 text-no-wrap width-6-pct">{{ headers[6].text }}</th>
                        <th class="grey lighten-3 pa-1 text-no-wrap width-6-pct">{{ headers[7].text }}</th>
                        <th class="grey lighten-3 pa-1 text-no-wrap width-5-pct">{{ headers[8].text }}</th>
                        <th class="grey lighten-3 pa-1 text-no-wrap width-5-pct">{{ headers[9].text }}</th>
                        <th class="grey lighten-3 pa-1 text-no-wrap width-1-pct">{{ headers[10].text }}</th>
                        <th class="grey lighten-3 pa-1 text-no-wrap width-7-pct">{{ headers[11].text }}</th>
                        <th class="grey lighten-3 pa-1 text-no-wrap width-6-pct">{{ headers[12].text }}</th>
                        <th class="grey lighten-3 pa-1 text-no-wrap width-6-pct">{{ headers[13].text }}</th>
                        <th class="grey lighten-3 pa-1 text-no-wrap width-7-pct">{{ headers[14].text }}</th>
                        <th class="grey lighten-3 pa-1 text-center width-1-pct">{{ headers[15].text }}</th>
                    </tr>
                    <tr>
                        <th class="grey lighten-3"></th>
                        <th class="grey lighten-3"></th>
                        <th class="grey lighten-3"></th>
                        <th class="grey lighten-3"></th>
                        <th class="grey lighten-3"></th>
                        <th class="grey lighten-3 pl-0 pr-2">
                            <v-select
                                :items="uofmDimensions"
                                :placeholder="$t('message.uofm')"
                                :value="StockOrder__smeasurement_id"
                                class="custom pl-1 bg-lightblue"
                                dense
                                hide-details="auto"
                                item-value="Measurement.id"
                                item-text="Measurement.title"
                                @change="updateStockOrder('StockOrder__smeasurement_id',$event)"
                            ></v-select>
                        </th>
                        <th class="grey lighten-3 pl-0 pr-2">
                            <v-select
                                :items="uofmDimensions"
                                :placeholder="$t('message.uofm')"
                                :value="StockOrder__wmeasurement_id"
                                class="custom pl-1 bg-lightblue"
                                dense
                                hide-details="auto"
                                item-value="Measurement.id"
                                item-text="Measurement.title"
                                @change="updateStockOrder('StockOrder__wmeasurement_id',$event)"
                            ></v-select>
                        </th>
                        <th class="grey lighten-3 pl-0 pr-2">
                            <v-select
                                :items="uofmDimensions"
                                :placeholder="$t('message.uofm')"
                                :value="StockOrder__lmeasurement_id"
                                class="custom pl-1 bg-lightblue"
                                dense
                                hide-details="auto"
                                item-value="Measurement.id"
                                item-text="Measurement.title"
                                @change="updateStockOrder('StockOrder__lmeasurement_id',$event)"
                            ></v-select>
                        </th>
                        <th class="grey lighten-3"></th>
                        <th class="grey lighten-3"></th>
                        <th class="grey lighten-3 pl-0 pr-2">
                            <v-select
                                :items="uofmQty"
                                :value="StockOrder__vmeasurement_id"
                                class="custom pl-1 bg-lightblue"
                                dense
                                hide-details="auto"
                                item-value="Measurement.id"
                                item-text="Measurement.title"
                                @change="updateStockOrder('StockOrder__vmeasurement_id',$event)"
                            ></v-select>
                        </th>
                        <th class="grey lighten-3 px-0">
                            <div class="d-flex flex-row">
                                <v-text-field
                                    class="custom pl-1 bg-lightblue"
                                    dense
                                    hide-details="auto"
                                    type="number"
                                    v-model="StockOrder__unitcost"
                                ></v-text-field>
                                <v-tooltip bottom>
                                    <template v-slot:activator="{ on }">
                                        <v-btn icon small @click="batchUpdateBundles('Stock.costprice', StockOrder__unitcost)">
                                            <v-icon color="grey" small v-on="on">fas fa-bolt</v-icon>
                                        </v-btn>
                                    </template>
                                    <span>{{ $t('message.updateAllBundles') }}</span>
                                </v-tooltip>
                            </div>
                        </th>
                        <th class="grey lighten-3 px-0">
                            <div class="d-flex flex-row">
                                <v-text-field
                                    class="custom pl-1 bg-lightblue"
                                    dense
                                    hide-details="auto"
                                    type="number"
                                    v-model="StockOrder__forwardingcost"
                                ></v-text-field>
                                <v-tooltip bottom>
                                    <template v-slot:activator="{ on }">
                                        <v-btn icon small @click="batchUpdateBundles('Stock.forwardingcharge', StockOrder__forwardingcost)">
                                            <v-icon color="grey" small v-on="on">fas fa-bolt</v-icon>
                                        </v-btn>
                                    </template>
                                    <span>{{ $t('message.updateAllBundles') }}</span>
                                </v-tooltip>
                            </div>
                        </th>
                        <th class="grey lighten-3 px-0">
                            <div class="d-flex flex-row">
                                <v-text-field
                                    class="custom pl-1 bg-lightblue"
                                    dense
                                    hide-details="auto"
                                    type="number"
                                    v-model="StockOrder__warehousecost"
                                ></v-text-field>
                                <v-tooltip bottom>
                                    <template v-slot:activator="{ on }">
                                        <v-btn icon small @click="batchUpdateBundles('Stock.warehousecost', StockOrder__warehousecost)">
                                            <v-icon color="grey" small v-on="on">fas fa-bolt</v-icon>
                                        </v-btn>
                                    </template>
                                    <span>{{ $t('message.updateAllBundles') }}</span>
                                </v-tooltip>
                            </div>
                        </th>
                        <th class="grey lighten-3"></th>
                        <th class="grey lighten-3"></th>
                    </tr>
                </thead>
            </template>
            <template v-slot:item.lineNumber="{ item }">
                <div class="text-center mt-2">{{ item.lineNumber }}</div>
            </template>
            <template v-slot:item.Stock.stockstate_id="{ item }">
                <div class="text-left mt-2 red--text" v-if="item.Stock.status == 'sold'">{{ $t('message.' + item.Stock.status) }}</div>
                <div class="text-left mt-2 green--text text--darken-4" v-if="item.Stock.status == 'available'">{{ $t('message.' + item.Stock.status) }}</div>
                <div class="text-left mt-2 text--darken-4" v-if="item.Stock.status == null">{{ $t('message.new') }}</div>
            </template>
            <template v-slot:item.Stock.bundleno="{ item }">
                <v-text-field
                    :value="item.Stock.bundleno"
                    autocomplete="off"
                    class="custom force-text-left pl-0 pr-0  bg-lightgrey font-weight-bold"
                    clearable
                    dense
                    hide-details="auto"
                    @change="updateBundleRowHandle( item.index, 'Stock.bundleno',$event)"
                    v-if="item.Stock.stockstate_id != 1"
                ></v-text-field>
                <div class="font-weight-bold mt-2 text-no-wrap" v-else>{{ item.Stock.bundleno }}</div>
            </template>
            <template v-slot:item.Stock.salesdescription_id="{ item }">
                <div class="d-flex flex-row align-center">
                    <div style="min-width: 50px !important;">{{ $t('message.product') + ":" }}</div>
                    <v-autocomplete
                        :items=allProductGroups
                        :placeholder="$t('message.startTypingToSearch')"
                        :value="item.Stock.productgroup_id"
                        class="custom pa-1 bg-lightgrey"
                        clearable
                        dense
                        hide-details="auto"
                        item-text="ProductGroup.title"
                        item-value="ProductGroup.id"
                        @change="updateBundleRowHandle( item.index, 'Stock.productgroup_id',$event)"
                    ></v-autocomplete>
                </div>
                <div class="d-flex flex-row align-center">
                    <div style="min-width: 50px !important;">{{ $t('message.species') + ":" }}</div>
                    <v-autocomplete
                        :id="item.index + 'species'"
                        :items="allSpecies"
                        :placeholder="$t('message.startTypingToSearch')"
                        :value="item.Stock.species_id"
                        autocomplete="off"
                        class="custom pl-0 pr-0 bg-lightgrey"
                        clearable
                        dense
                        hide-details="auto"
                        item-value="Species.id"
                        item-text="Species.title"
                        return-object
                        @focus="currentRowIndex = item.index"
                        @change="updateBundleRowHandle( item.index, 'Stock.species_id',$event)"
                        v-if="item.Stock.stockstate_id != 1"
                    ></v-autocomplete>
                    <div class="mt-2 text-no-wrap" v-else>{{ item.Species.title }}</div>
                </div>
                <div class="d-flex flex-row align-center">
                    <div style="min-width: 50px !important;">{{ $t('message.grade') + ":" }}</div>
                    <v-autocomplete
                        :id="item.index + 'grade'"
                        :items="allGrades"
                        :placeholder="$t('message.startTypingToSearch')"
                        :value="item.Stock.grade_id"
                        autocomplete="off"
                        class="custom pl-0 pr-0 bg-lightgrey"
                        clearable
                        dense
                        hide-details="auto"
                        item-text="Grade.title"
                        item-value="Grade.id"
                        return-object
                        @focus="currentRowIndex = item.index"
                        @change="updateBundleRowHandle( item.index, 'Stock.grade_id',$event)"
                        v-if="item.Stock.stockstate_id != 1"
                    >
                        <template v-slot:selection="{ item }">
                            <div class="text-wrap" style="width: 100%">{{ item.Grade.title }}</div>
                        </template>
                    </v-autocomplete>
                    <div class="mt-2 text-no-wrap" v-else>{{ item.Grade.title }}</div>
                </div>
                <div class="d-flex flex-row align-center">
                    <div style="min-width: 50px !important;">{{ $t('message.spec') + ":" }}</div>
                    <v-lazy>
                        <v-textarea
                            :key="'description_' + item.index.toString()"
                            :value="item.Stock.salesdescription"
                            auto-grow
                            autocomplete="off"
                            class="custom force-text-left pa-1 bg-lightgrey"
                            clearable
                            dense
                            hide-details="auto"
                            rows="1"
                            style="width: 225px !important;"
                            v-if="item.Stock.stockstate_id != 1"
                            @change="updateBundleRowHandle( item.index, 'Stock.salesdescription',$event)"
                        ></v-textarea>
                        <div class="text-left mt-2 text-no-wrap truncate" v-else>{{ item.Stock.salesdescription }}</div>
                    </v-lazy>
                </div>
                <div class="d-flex flex-row align-center">
                    <div style="min-width: 50px !important;">{{ $t('message.mc') + ":" }}</div>
                    <v-select
                        :id="item.index + 'mc'"
                        :placeholder="$t('message.startTypingToSearch')"
                        :items="allMoistureContent"
                        :value="item.Stock.mc_id"
                        class="custom pa-1 bg-lightgrey"
                        clearable
                        dense
                        hide-details="auto"
                        item-text="Mc.title"
                        item-value="Mc.id"
                        @focus="currentRowIndex = item.index"
                        @change="updateBundleRowHandle( item.index, 'Stock.mc_id',$event)"
                    ></v-select>
                </div>
            </template>
            <template v-slot:item.Stock.fsc="{ item }">
                <v-select
                    :items="allCertifications"
                    :value="item.Stock.fsc"
                    autocomplete="off"
                    class="custom pl-0 pr-0 bg-lightgrey"
                    clearable
                    dense
                    hide-details="auto"
                    item-text="Certification.title"
                    item-value="Certification.title"
                    @change="updateBundleRowHandle( item.index, 'Stock.fsc',$event)"
                    v-if="item.Stock.stockstate_id != 1"
                ></v-select>
                <div class="mt-2 text-no-wrap" v-else>{{ item.Stock.fsc }}</div>
                <v-text-field
                    :value="item.Stock.fsc_description"
                    autocomplete="off"
                    class="custom force-text-left pl-0 pr-0 bg-lightgrey"
                    clearable
                    dense
                    hide-details="auto"
                    @change="updateBundleRowHandle( item.index, 'Stock.fsc_description',$event)"
                    v-if="item.Stock.stockstate_id != 1 && false"
                ></v-text-field>
                <div class="mt-2 text-no-wrap" v-if="false">{{ item.Stock.fsc_description }}</div>
            </template>
            <template v-slot:item.Stock.thick="{ item }">
                <div class="d-flex flex-row">
                    <v-text-field
                        :value="item.Stock.thick"
                        autocomplete="off"
                        class="custom force-text-left pl-0 pr-0 bg-lightgrey"
                        clearable
                        dense
                        hide-details="auto"
                        @change="updateBundleRowHandle( item.index, 'Stock.thick',$event)"
                        v-if="item.Stock.stockstate_id != 1"
                    ></v-text-field>
                    <div class="mt-2 text-no-wrap" v-else>{{ item.Stock.thick }}</div>
                    <div class="ml-1 mt-2">{{ getUofmDimension(item.Stock.smeasurement_id) }}</div>
                </div>
            </template>
            <template v-slot:item.Stock.width="{ item }">
                <div class="d-flex flex-row">
                    <v-text-field
                        :value="item.Stock.width"
                        autocomplete="off"
                        class="custom force-text-left pl-0 bg-lightgrey"
                        clearable
                        dense
                        hide-details="auto"
                        @change="updateBundleRowHandle( item.index, 'Stock.width',$event)"
                        v-if="item.Stock.stockstate_id != 1"
                    ></v-text-field>
                    <div class="mt-2 text-no-wrap" v-else>{{ item.Stock.width }}</div>
                    <div class="ml-1 mt-2">{{ getUofmDimension(item.Stock.wmeasurement_id) }}</div>
                </div>
            </template>
            <template v-slot:item.Stock.length="{ item }">
                <div class="d-flex flex-row">
                    <v-text-field
                        :value="item.Stock.length"
                        autocomplete="off"
                        class="custom force-text-left pl-0 bg-lightgrey"
                        clearable
                        dense
                        hide-details="auto"
                        @change="updateBundleRowHandle( item.index, 'Stock.length',$event)"
                        v-if="item.Stock.stockstate_id != 1"
                    ></v-text-field>
                    <div class="mt-2 text-no-wrap" v-else>{{ item.Stock.length }}</div>
                    <div class="ml-1 mt-2">{{ getUofmDimension(item.Stock.lmeasurement_id) }}</div>
                </div>
            </template>
            <template v-slot:item.Stock.piece="{ item }">
                <v-text-field
                    :value="item.Stock.piece"
                    autocomplete="off"
                    class="custom force-text-left pl-0 bg-lightgrey"
                    clearable
                    dense
                    hide-details="auto"
                    min="0"
                    type="number"
                    @change="updateBundleRowHandle( item.index, 'Stock.piece',$event)"
                    v-if="item.Stock.stockstate_id != 1"
                ></v-text-field>
                <div class="mt-2 text-no-wrap" v-else>{{ item.Stock.piece }}</div>
            </template>
            <template v-slot:item.Stock.volume="{ item }">
                    <v-text-field
                        :value="item.Stock.volume"
                        autocomplete="off"
                        class="custom force-text-left pl-0 bg-lightgrey"
                        clearable
                        dense
                        hide-details="auto"
                        min="0"
                        type="number"
                        @change="updateBundleRowHandle( item.index, 'Stock.volume',$event)"
                        v-if="item.Stock.stockstate_id != 1"
                    ></v-text-field>
                    <div class="mt-2 text-no-wrap" v-else>{{ item.Stock.volume }}</div>
            </template>
            <template v-slot:item.Stock.soldmeasurement_id="{ item }">
                <div class="ml-0 mt-2">{{ getUofmQty(item.Stock.soldmeasurement_id) }}</div>
            </template>
            <template v-slot:item.Stock.costprice="{ item }">
                <v-text-field
                    :value="item.Stock.costprice"
                    autocomplete="off"
                    class="custom force-text-left pl-0 bg-lightgrey"
                    clearable
                    dense
                    hide-details="auto"
                    min="0"
                    type="number"
                    @change="updateBundleRowHandle( item.index, 'Stock.costprice',$event)"
                    v-if="item.Stock.stockstate_id != 1"
                ></v-text-field>
                <div class="mt-2 text-no-wrap" v-else>{{ item.Stock.costprice }}</div>
            </template>
            <template v-slot:item.Stock.forwardingcharge="{ item }">
                <v-text-field
                    :value="item.Stock.forwardingcharge"
                    autocomplete="off"
                    class="custom force-text-left pl-0 bg-lightgrey"
                    clearable
                    dense
                    hide-details="auto"
                    min="0"
                    type="number"
                    @change="updateBundleRowHandle( item.index, 'Stock.forwardingcharge',$event)"
                    v-if="item.Stock.stockstate_id != 1"
                ></v-text-field>
                <div class="mt-2 text-no-wrap" v-else>{{ item.Stock.forwardingcharge }}</div>
            </template>
            <template v-slot:item.Stock.warehousecost="{ item }">
                <v-text-field
                    :value="item.Stock.warehousecost"
                    autocomplete="off"
                    class="custom force-text-left pl-0 bg-lightgrey"
                    clearable
                    dense
                    hide-details="auto"
                    min="0"
                    type="number"
                    @change="updateBundleRowHandle( item.index, 'Stock.warehousecost',$event)"
                    v-if="item.Stock.stockstate_id != 1"
                ></v-text-field>
                <div class="mt-2 text-no-wrap" v-else>{{ item.Stock.warehousecost }}</div>
            </template>
            <template v-slot:item.Stock.sellingprice="{ item }">
                <v-text-field
                    :value="item.Stock.sellingprice"
                    autocomplete="off"
                    class="custom force-text-left pl-0 bg-lightgrey"
                    clearable
                    dense
                    hide-details="auto"
                    min="0"
                    type="number"
                    @change="updateBundleRowHandle( item.index, 'Stock.sellingprice',$event)"
                    v-if="item.Stock.stockstate_id != 1"
                ></v-text-field>
                <div class="mt-2 text-no-wrap" v-else>{{ item.Stock.sellingprice }}</div>
            </template>
            <template v-slot:item.Stock.id="{ item }">
                <div class="d-flex flex-row" v-if="[3,2].includes(item.Stock.stockstate_id)">
                    <v-tooltip bottom>
                        <template v-slot:activator="{ on }">
                            <v-btn icon @click="updateBundleAvailability(item.index, item.Stock.id, item.Stock.bundleno)" v-on="on">
                                <v-icon color="grey" small>fas fa-wave-square</v-icon>
                            </v-btn>
                        </template>
                        <span>{{ $t('message.changeAvailability') }}</span>
                    </v-tooltip>
                    <v-tooltip bottom>
                        <template v-slot:activator="{ on }">
                            <v-btn icon @click="deleteBundle(item.index, item.Stock.id, item.Stock.bundleno)" v-on="on">
                                <v-icon color="grey" small>delete</v-icon>
                            </v-btn>
                        </template>
                        <span>{{ $t('message.deleteThisItem') }}</span>
                    </v-tooltip>
                </div>
                <div v-else class="mt-2 text-center font-weight-bold">{{ $t('message.na') }}</div>
            </template>
            <template v-slot:footer>
                <div class="pt-0 d-flex justify-space-between">
                    <div class="col-4 col-lg-4 col-md-3 pb-0 px-0 d-flex align-end">
                        <v-btn
                            color="success"
                            small
                            @click="addBundleRow(statePrefix)"
                        >{{ Bundles.length == 0 ? $t('message.addBundle') : $t('message.addMore') }}
                        </v-btn>
                        <v-btn
                            :loading="resettingStockOrderBundles"
                            class="ml-2"
                            color="default"
                            small
                            v-if="updateMode == false"
                            @click="resetBundles()"
                        ><v-icon>mdi-repeat</v-icon> {{ $t('message.reset') }}
                        </v-btn>
                        <v-btn
                            class="ml-2"
                            color="blue lighten-4"
                            small
                            @click="uploadByExcelDialog = true"
                        ><v-icon color="black darken-1">mdi-upload</v-icon> {{ $t('message.uploadBundlesByExcelFile') }}
                        </v-btn>
                        <v-btn small class="ml-2" :href="stockUploadTemplateFile">
                            <v-icon color="black darken-1">mdi-download</v-icon> {{ $t('message.stockUploadTemplate') }}
                        </v-btn>
                    </div>
                    <div class="dense-inputs col-8 col-lg-8 col-md-9 pa-0">
                        <v-row class="justify-end">
                            <v-col cols="2" lg="2" md="2" xs="6" class="pb-0">
                                <div class="text-right font-sm font-weight-black">{{ $t('message.currency') }}</div>
                                <v-select
                                    :items="allCurrencies"
                                    autocomplete="off"
                                    class="custom pt-0 pb-2 mt-0 bg-lightgrey"
                                    clearable
                                    dense
                                    hide-details="auto"
                                    item-text="Currency.code"
                                    item-value="Currency.id"
                                    reverse
                                    v-model="StockOrder__currency_id"
                                ></v-select>
                            </v-col>
                            <v-col cols="2" lg="2" md="2" xs="6" class="pb-0">
                                <div class="text-right font-sm font-weight-black">
                                    {{ stockOrderCurrency.Currency.code + ":" + officeCurrency.Currency.code + " " + $t('message.exRate') }}
                                </div>
                                <v-text-field
                                    :value="StockOrder__currencyrate"
                                    autocomplete="off"
                                    class="custom pt-0 pb-2 mt-0 bg-lightgrey"
                                    clearable
                                    dense
                                    hide-details="auto"
                                    type="number"
                                    @change="StockOrder__currencyrate = $event"
                                >
                                </v-text-field>
                            </v-col>
                        </v-row>
                    </div>
                </div>
            </template>
        </v-data-table>
        <BundleExcelUpload
            :stock-order-id="StockOrder__id"
            :dialog.sync="uploadByExcelDialog"
            :update-mode="updateMode"
            @dialog-closed="uploadByExcelDialogClosed"
            @upload-done="bundlesUploaded"
        ></BundleExcelUpload>
    </div>
</template>

<script>
import {mapActions, mapGetters, mapMutations} from "vuex";
import {mapFields, mapMultiRowFields} from "vuex-map-fields";
import {getSafe, numberFormat} from "../../helpers/helpers";
import { api } from "../../api";
import {mapFieldsPrefixed, mapMultiRowFieldsPrefixed} from "Helpers/vuex-map-fields-prefixed";

const BundleExcelUpload = () => import("Components/Appic/BundleExcelUpload");

export default {
    name: "BundleList",
    components: {BundleExcelUpload},
    props: ['updateMode'],
    data() {
        return {
            certifications: [
                {text: 'FSCCW', value: 'FSCCW'},
                {text: 'FSCCOC', value: 'FSCCOC'},
                {text: 'PEFC', value: 'PEFC'}
            ],
            currentRowIndex: 0,
            dialogs: {
                confirm:  false,
                confirm_message: ""
            },
            disablePagination: true,
            itemsPerPage: -1,
            loading: {
                addBundle: false,
            },
            resettingStockOrderBundles: false,
            StockOrder__unitcost: 0,
            stockUploadTemplateFile: process.env.VUE_APP_API_BASE_URL + '/stockorders/stock-upload-template',
            uploadByExcelDialog: false
        }
    },
    computed: {
        statePrefix() {
            if (this.updateMode) {
                return 'update'
            } else {
                return 'current'
            }
        },
        ...mapFieldsPrefixed('stockorder',{
            StockOrder__currency_id: 'StockOrder.currency_id',
            StockOrder__currencyrate: 'StockOrder.currencyrate',
            StockOrder__currencyratedate: 'StockOrder.currencyratedate',
            StockOrder__forwardingcost: 'StockOrder.forwardingcost',
            StockOrder__id: 'StockOrder.id',
            StockOrder__lmeasurement_id: 'StockOrder.lmeasurement_id',
            StockOrder__office_id: 'StockOrder.office_id',
            StockOrder__smeasurement_id: 'StockOrder.smeasurement_id',
            StockOrder__vmeasurement_id: 'StockOrder.vmeasurement_id',
            StockOrder__warehousecost: 'StockOrder.warehousecost',
            StockOrder__wmeasurement_id: 'StockOrder.wmeasurement_id',
        }, 'statePrefix'),
        ...mapFields('stockorder',{
            newStockOrderSaved: 'newStockOrderSaved',
            newStockOrderUnsaved: 'newStockOrderUnsaved',
            updatedStockOrderSaved: 'updatedStockOrderSaved',
            updatedStockOrderUnsaved: 'updatedStockOrderUnsaved',
        }),
        ...mapMultiRowFieldsPrefixed('stock',{
            Bundles: 'Bundles'
        }, 'statePrefix'),
        ...mapGetters('certification',{
            allCertifications: 'allCertifications'
        }),
        ...mapGetters('currency',{
            allCurrencies: 'allCurrencies'
        }),
        ...mapGetters('grade',{
            allGrades: 'allGrades',
            findGradeById: 'findGradeById'
        }),
        ...mapGetters('mc', {
            allMoistureContent: 'allMoistureContent'
        }),
        ...mapGetters('productgroup',{
            allProductGroups: 'allProductGroups'
        }),
        ...mapGetters('species', {
            allSpecies: 'allSpecies',
            findSpeciesById: 'findSpeciesById'
        }),
        ...mapGetters('spec',{
            allSpecs: 'allSpecs',
            allCleanedSpecs: 'allCleanedSpecs',
            findSpecById: 'findSpecById'
        }),
        ...mapGetters('thickness', {
            allThickness: 'allThickness'
        }),
        ...mapGetters([
            // 'certificationsNew',
            'currencies',
            'offices',
            'stockFscTypes',
            'uofmQty',
            'uofmDimensions'
        ]),
        headers(){
            return [
                {
                    id: 0,
                    text: this.$t('message.lineNo'),
                    value: 'lineNumber'
                },
                {
                    id: 1,
                    text: this.$t('message.status'),
                    value: 'Stock.stockstate_id'
                },
                {
                    id: 2,
                    text: this.$t('message.bundleNo'),
                    value: 'Stock.bundleno'
                },
                {
                    id: 3,
                    text: this.$t('message.certification'),
                    value: 'Stock.fsc'
                },
                {
                    id: 4,
                    text: this.$t('message.description'),
                    value: 'Stock.salesdescription_id'
                },
                {
                    id: 5,
                    text: this.$t('message.thick'),
                    value: 'Stock.thick'
                },
                {
                    id: 6,
                    text: this.$t('message.width'),
                    value: 'Stock.width'
                },
                {
                    id: 7,
                    text: this.$t('message.length'),
                    value: 'Stock.length'
                },
                {
                    id: 8,
                    text: this.$t('message.pcs'),
                    value: 'Stock.piece'
                },
                {
                    id: 9,
                    text: this.$t('message.quantity'),
                    value: 'Stock.volume'
                },
                {
                    id: 10,
                    text: this.$t('message.unit'),
                    value: 'Stock.soldmeasurement_id'
                },
                {
                    id: 11,
                    text: this.$t('message.buyingPrice'),
                    value: 'Stock.costprice'
                },
                {
                    id: 12,
                    text: this.$t('message.freightCost'),
                    value: 'Stock.forwardingcharge'
                },
                {
                    id: 13,
                    text: this.$t('message.whCost'),
                    value: 'Stock.warehousecost'
                },
                {
                    id: 14,
                    text: this.$t('message.salesPrice'),
                    value: 'Stock.sellingprice'
                },
                {
                    id: 15,
                    text: this.$t('message.actions'),
                    value: 'Stock.id'
                },
            ]
        },
        officeCurrency () {
            let office = this.offices.find(o => o.Office.id == this.StockOrder__office_id)
            if(office) {
                return this.allCurrencies.find(c => c.Currency.id == office.Office.currency_id)
            } else {
                return this.allCurrencies.find(c => c.Currency.id == 1)
            }
        },
        stockOrderCurrency () {
            if(this.StockOrder__currency_id) {
                return this.allCurrencies.find(c => c.Currency.id == this.StockOrder__currency_id)
            } else {
                return this.allCurrencies.find(c => c.Currency.id == 1)
            }
        }
    },
    methods: {
        ...mapActions('certification',{
            getAllCertifications: 'getAllCertifications'
        }),
        ...mapActions('currency', {
            getAllCurrencies: 'getAllCurrencies'
        }),
        ...mapActions('grade', {
            getAllGrades: 'getAllGrades'
        }),
        ...mapActions('mc', {
            getAllMoistureContent: 'getAllMoistureContent'
        }),
        ...mapActions('productgroup', {
            getAllProductGroups: 'getAllProductGroups'
        }),
        ...mapActions('size', {
            getAllSizes: 'getAllSizes'
        }),
        ...mapActions('species', {
            getAllSpecies: 'getAllSpecies'
        }),
        ...mapActions('spec', {
            getAllSpecs: 'getAllSpecs',
            getAllCleanedSpecs: 'getAllCleanedSpecs'
        }),
        ...mapActions('stock',{
            addBundleRow: 'addBundleRow',
            deleteStockOrderBundle: 'deleteStockOrderBundle',
            getBundlesByStockOrder: 'getBundlesByStockOrder',
            resetStockOrderBundles: 'resetStockOrderBundles',
            updateBundleStatus: 'updateBundleStatus'
        }),
        ...mapMutations('stock',{
            deleteStockOrderBundleRow: 'deleteStockOrderBundleRow'
        }),
        batchUpdateBundles (field, value) {
            this.Bundles.forEach((bundle, idx) => {
                if(bundle.Stock.stockstate_id == 3) {
                    this.$store.commit('stock/updateBundles', {
                        index: idx,
                        field: field,
                        prefix: this.statePrefix,
                        value: value,
                    });
                }
            })
        },
        bundlesUploaded () {
            this.uploadByExcelDialog = false;
        },
        async deleteBundle( itemIndex, itemId, bundleNo ) {
            if (await this.$root.$confirm(this.$t('message.delete') + " " + bundleNo, this.$t('message.confirmations.continueDeleteBundleAction'), {color: 'red'})) {
                let promise = new Promise((resolve, reject) => {
                    let payload = {
                        index: itemIndex,
                        prefix: this.statePrefix
                    }
                    this.deleteStockOrderBundleRow(payload)
                    resolve('ok')
                });
                promise.then(() => {
                    if(itemId != null){
                        this.deleteStockOrderBundle({
                                stockOrderId: this.StockOrder__id,
                                itemId: itemId
                            })
                            .then((response)=>{
                                let payload = {
                                    prefix: this.statePrefix,
                                    stockOrderId: this.StockOrder__id
                                }
                                this.getBundlesByStockOrder(payload)
                                    .then( () => {
                                        this.updatedStockOrderUnsaved = false
                                    })
                                    .catch(() => {
                                        this.updatedStockOrderUnsaved = false
                                    })
                            })
                    }
                })
            }
        },
        getUofmDimension( uofm_id ) {
            let uofm = this.uofmDimensions.find(u => u.Measurement.id == uofm_id)?.Measurement.title
            return uofm;
        },
        getUofmQty( uofm_id ) {
            let uofm = this.uofmQty.find(u => u.Measurement.id == uofm_id)?.Measurement.title
            return uofm;
        },
        async resetBundles () {
            if (await this.$root.$confirm(this.$t('message.resetStockArrivalBundles'), this.$t('message.confirmations.continueStockArrivalBundlesReset'), {color: 'orange'})) {
                this.resettingStockOrderBundles = true
                this.resetStockOrderBundles(this.statePrefix).then(() => {
                    this.resettingStockOrderBundles = false
                })
            }
        },
        async updateBundleAvailability( itemIndex, itemId, bundleNo ) {
            if (await this.$root.$confirm(this.$t('message.updateBundle') + " " + bundleNo, this.$t('message.confirmations.continueUpdateBundleAvailabilityAction'), {color: 'red'})) {
                let promise = new Promise((resolve, reject) => {
                    let payload = {
                        itemId: itemId,
                        status: 1,
                        stockOrderId: this.StockOrder__id,
                    }
                    this.updateBundleStatus(payload)
                    resolve('ok')
                });
                promise.then(() => {
                    let payload = {
                        prefix: this.statePrefix,
                        stockOrderId: this.StockOrder__id
                    }
                    this.getBundlesByStockOrder(payload)
                        .then( () => {
                            this.updatedStockOrderUnsaved = false
                        })
                        .catch(() => {
                            this.updatedStockOrderUnsaved = false
                        })
                })
            }
        },
        updateBundleRowHandle( idx, field, value ) {
            switch(field) {
            case 'Stock.soldmeasurement_id':
                var payload = {
                    index: idx,
                    field: field,
                    prefix: this.statePrefix,
                    value: value,
                }
                //assign Soldmeasurement property in Stock
                let measurement = this.uofmQty.filter(uofm => uofm.Measurement.id === value)[0];
                if(measurement != null) {
                    this.$store.commit('stock/updateBundles', {
                        index: idx,
                        field: 'Soldmeasurement.id',
                        prefix: this.statePrefix,
                        value: measurement.Measurement.id != null ? measurement.Measurement.id : null
                    })
                    this.$store.commit('stock/updateBundles', {
                        index: idx,
                        field: 'Soldmeasurement.title',
                        prefix: this.statePrefix,
                        value: measurement.Measurement.title != null ? measurement.Measurement.title : null
                    })
                }
                break;
            case 'Stock.species_id':
                var payload = {
                    index: idx,
                    field: field,
                    prefix: this.statePrefix,
                    value: value?.Species?.id
                }
                // assign Species property in Stock
                this.$store.commit('stock/updateBundles', {
                    index: idx,
                    field: 'Species.id',
                    prefix: this.statePrefix,
                    value: value?.Species?.id
                })
                this.$store.commit('stock/updateBundles', {
                    index: idx,
                    field: 'Species.title',
                    prefix: this.statePrefix,
                    value: value?.Species?.title
                })
                break
            case 'Stock.grade_id':
                var payload = {
                    index: idx,
                    field: field,
                    prefix: this.statePrefix,
                    value: value?.Grade?.id
                }
                // assign Grade property in Stock
                this.$store.commit('stock/updateBundles', {
                    index: idx,
                    field: 'Grade.id',
                    prefix: this.statePrefix,
                    value: value?.Grade?.id
                })
                this.$store.commit('stock/updateBundles', {
                    index: idx,
                    field: 'Grade.title',
                    prefix: this.statePrefix,
                    value: value?.Grade?.title
                })
                break
            case 'Stock.fsc':
                var payload = {
                    index: idx,
                    field: field,
                    prefix: this.statePrefix,
                    value: value,
                }
                const certification = this.allCertifications.find(c => c.Certification.title == value)
                if(certification){
                    this.$store.commit('stock/updateBundles', {
                        index: idx,
                        field: 'Stock.certification_id',
                        prefix: this.statePrefix,
                        value: certification.Certification.id
                    })
                }
                break
            default:
                var payload = {
                    index: idx,
                    field: field,
                    prefix: this.statePrefix,
                    value: value,
                }
            }
            let promise = new Promise((resolve, reject) => {
                this.$store.commit('stock/updateBundles', payload)
                resolve('ok')
            });
            promise.then(() => {
                console.log('Bundle updated...')
            })
        },
        updateStockOrder ( field, value ) {
            this[field] = value //update StockOrder field
            switch (field) {
                case 'StockOrder__smeasurement_id':
                    //Update all bundle smeasurement_ids
                    this.Bundles.forEach((bundle, idx) => {
                        if(bundle.Stock.stockstate_id == 3) {
                            this.$store.commit('stock/updateBundles', {
                                index: idx,
                                field: 'Stock.smeasurement_id',
                                prefix: this.statePrefix,
                                value: value,
                            });
                        }
                    })
                    break;
                case 'StockOrder__wmeasurement_id':
                    //Update all bundle wmeasurement_ids
                    this.Bundles.forEach((bundle, idx) => {
                        if(bundle.Stock.stockstate_id == 3) {
                            this.$store.commit('stock/updateBundles', {
                                index: idx,
                                field: 'Stock.wmeasurement_id',
                                prefix: this.statePrefix,
                                value: value,
                            });
                        }
                    })
                    break;
                case 'StockOrder__lmeasurement_id':
                    //Update all bundle lmeasurement_ids
                    this.Bundles.forEach((bundle, idx) => {
                        if(bundle.Stock.stockstate_id == 3) {
                            this.$store.commit('stock/updateBundles', {
                                index: idx,
                                field: 'Stock.lmeasurement_id',
                                prefix: this.statePrefix,
                                value: value,
                            });
                        }
                    })
                    break;
                case 'StockOrder__vmeasurement_id':
                    //Update all bundle soldmeasurement_ids
                    this.Bundles.forEach((bundle, idx) => {
                        if(bundle.Stock.stockstate_id == 3) {
                            this.$store.commit('stock/updateBundles', {
                                index: idx,
                                field: 'Stock.soldmeasurement_id',
                                prefix: this.statePrefix,
                                value: value,
                            });
                        }
                    })
                    break;
            }
        },
        uploadByExcelDialogClosed() {
            this.uploadByExcelDialog = false;
        }
    },
    created () {
        if(this.Bundles.length > 20){
            this.disablePagination = false
            this.itemsPerPage = 10
        }
        if(this.allSpecies.length == 0) this.getAllSpecies()
        if(this.allGrades.length == 0) this.getAllGrades()
        if(this.allCertifications.length == 0) this.getAllCertifications()
        if(this.allCurrencies.length == 0) this.getAllCurrencies()
        if(this.allMoistureContent.length == 0) this.getAllMoistureContent()
        if(this.allProductGroups.length == 0) this.getAllProductGroups()
        if(this.allCleanedSpecs.length == 0) this.getAllCleanedSpecs()
    },
    mounted() {
        this.$store.watch(
            function (state) {
                return state.appic.stock.update.Bundles
            },
            () => {
                this.updatedStockOrderUnsaved = true
            },
            {deep: true}
        )
        this.$store.watch(
            function (state) {
                return state.appic.stock.current.Bundles
            },
            () => {
                this.newStockOrderUnsaved = true
            },
            {deep: true}
        )
    }
}
</script>

<style>
    .bg-lightblue {
        background-color: lightblue;
    }
    .bg-lightgrey {
        background-color: #f5f5f5;
    }
    .v-text-field__slot input {
        text-align: right;
    }
    .custom.v-text-field {
        font-size: 0.75rem !important;
    }
    .custom.v-text-field>.v-input__control>.v-input__slot:before {
        border-style: none;
    }
    .custom.v-text-field>.v-input__control>.v-input__slot:after {
        border-style: none;
    }
    .v-data-table td {
        padding: 0 5px;
    }
    .font-xs {
        font-size: 0.65rem;
    }
    .custom.v-select .v-icon {
        font-size: 18px !important;
    }
    .truncate {
        width: 200px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }
    .v-data-table tbody td {
        vertical-align: top !important;
        padding-left: 5px !important;
        padding-right: 5px !important;
        height: 40px !important;
        font-size: 0.80rem !important;
    }
    .row-reverse { flex-direction: row-reverse; }
    .force-text-left {
        text-align: left !important;
    }
    .v-text-field.v-input--dense:not(.v-text-field--enclosed):not(.v-text-field--full-width) .v-input__append-inner .v-input__icon>.v-icon, .v-text-field.v-input--dense:not(.v-text-field--enclosed):not(.v-text-field--full-width) .v-input__prepend-inner .v-input__icon>.v-icon {
        margin-top: 0px !important;
        font-size: 18px;
    }

    .v-application--is-ltr .v-text-field .v-input__append-inner {
        margin-left: auto !important;
        padding-left: 0px !important;
    }
</style>